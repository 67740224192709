import Box from "@vertikal/e-prospera.ui.box";
import Button from "@vertikal/e-prospera.ui.button";
import Icon from "@vertikal/e-prospera.ui.icon";
import Input from "@vertikal/e-prospera.ui.input";
import Loader from "@vertikal/e-prospera.ui.loader";
import Text from "@vertikal/e-prospera.ui.text";
import { graphql, Link, navigate } from "gatsby";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Helmet from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import GlobalSettings from "../../components/GlobalSettings";
import MainMenu from "../../components/MainMenu/MainMenu";

import {
  Content,
  FormWrapper,
  NotFoundCircle,
  ResponsiveButton,
  Result,
  ResultCharacter,
  ResultColor,
  ResultInfo,
  ResultsGrid,
  ResultsHeader,
  SmallFormWrapper,
  SubText,
  WideFormField,
} from "../../styles/business/search.styles";
import { getPreviousNames } from "../../utils/entities";

const BusinessSearch = ({ location }) => {
  const [t] = useTranslation();
  const [search, setSearch] = useState("");
  const [searchError, setSearchError] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  const searchQuery = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const searchParams = params.get("search") || "";
    return searchParams;
  }, [location.search]);

  useEffect(() => {
    const searchEntities = async (search) => {
      let query = "";
      if (search.length < 3) {
        return;
      }
      setLoading(true);
      if (search.length === 14) {
        query = `permitNumber=${search}`;
      } else {
        query = `fullName=${search}`;
      }
      const req = await fetch(
        `${process.env.GATSBY_API_ENDPOINT_TWN}/business-backend/public/entities?${query}`
      );
      const res = await req.json();
      setLoading(false);
      setSearchResults(res);
    };
    const params = new URLSearchParams(location.search);
    const searchParams = params.get("search") || "";
    setSearch(searchParams);
    if (searchParams.length < 3 && location.search.includes("?search")) {
      setSearchError(true);
      return;
    }
    searchEntities(searchParams);
  }, [location.search]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchError(false);
    const values = new FormData(e.target);
    const searchParams = values.get("search") || "";
    if (searchParams.length < 3 && location.search.includes("?search")) {
      setSearchError(true);
      return;
    }
    console.log(searchParams);
    navigate(`?search=${encodeURI(searchParams)}`);
  };

  return (
    <>
      <Helmet>
        <title>{t("public.business_search.title")}</title>
        <meta name="title" content={t("public.business_search.meta.title")} />
        <meta
          name="description"
          content={t("public.business_search.meta.description")}
        />
        <link rel="canonical" href="/business" />
      </Helmet>
      <GlobalSettings />
      <MainMenu darkFooter={false}>
        <Content>
          <Box style={{ alignSelf: "center" }}>
            <Breadcrumbs theme="dark">
              <Link to="/">
                <Trans>common.home</Trans>
              </Link>
              <Link to="/business">
                <Trans>common.business</Trans>
              </Link>
              <span>
                <Trans>common.business_search</Trans>
              </span>
            </Breadcrumbs>
          </Box>
          {!searchQuery || searchQuery.length < 3 ? (
            <>
              <FormWrapper autoComplete="off" onSubmit={handleSearch}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="1"
                  textAlign="center"
                  alignItems="center"
                >
                  <Text variant="h2" as="h1">
                    <Trans>public.business_search.hero.title</Trans>
                  </Text>
                  <Box maxWidth="32rem">
                    <Text>
                      <Trans>public.business_search.hero.subtitle</Trans>
                    </Text>
                  </Box>
                </Box>
                <Box
                  maxWidth="39rem"
                  display="flex"
                  gap="1"
                  alignItems="flex-start"
                  width="100%"
                  style={{ height: "6rem" }}
                >
                  <WideFormField
                    error={searchError}
                    showError={searchError}
                    errorText={t("public.business_search.search_error")}
                  >
                    <Input
                      ref={inputRef}
                      getValue={setSearch}
                      value={search}
                      name="search"
                      placeholder={t("public.business_search.placeholder")}
                      id="search-box"
                    />
                  </WideFormField>
                  <ResponsiveButton
                    type="submit"
                    id="big-search-submit"
                    rightIcon="search"
                  >
                    <Trans>common.search</Trans>
                  </ResponsiveButton>
                </Box>
              </FormWrapper>
            </>
          ) : (
            <Box>
              <ResultsHeader>
                <Text variant="h2" as="h1">
                  <Trans components={{ query: searchQuery }}>
                    public.business_search.search_results_for
                  </Trans>
                </Text>
                <SmallFormWrapper onSubmit={handleSearch}>
                  <WideFormField
                    error={searchError}
                    showError
                    errorText={t("public.business_search.search_error")}
                  >
                    <Input
                      value={search}
                      getValue={setSearch}
                      id="search-box"
                      name="search"
                      ref={inputRef}
                      action={
                        <Button
                          color="accent"
                          type="button"
                          variant="ghost"
                          id="clear"
                          style={{ padding: "0.5rem" }}
                          onClick={() => {
                            setSearch("");
                          }}
                          icon="close"
                        />
                      }
                    />
                  </WideFormField>
                  <Button
                    iconOnly
                    icon="search"
                    id="small-search-submit"
                    type="submit"
                  />
                </SmallFormWrapper>
              </ResultsHeader>
              {loading ? (
                <Loader size="large" />
              ) : (
                <>
                  {searchResults.length > 0 ? (
                    <ResultsGrid>
                      {searchResults.map((result) => {
                        return (
                          <Result
                            key={result._id}
                            onClick={() => navigate(`/entities/${result._id}`)}
                            elevation={3}
                            interactive
                            borderRadius={8}
                          >
                            <ResultColor />
                            <ResultCharacter>
                              <Text variant="h4">
                                {result.fullName[0].toUpperCase()}
                              </Text>
                            </ResultCharacter>
                            <ResultInfo>
                              <Text
                                variant="h5"
                                style={{ wordBreak: "break-all" }}
                              >
                                {result.fullName}
                              </Text>
                              {!!result?.previousNames?.length &&
                                !result.fullName.includes(searchQuery) && (
                                  <SubText>
                                    {`${t(
                                      "common.invalid_names"
                                    )}: ${getPreviousNames(
                                      result?.previousNames
                                    )}`}
                                  </SubText>
                                )}
                              <Text color="success">
                                <Trans>
                                  common.{result.status.toLowerCase()}
                                </Trans>
                              </Text>
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap="0.25"
                              >
                                <Text variant="p-sm" color="gray">
                                  <Trans
                                    components={{
                                      permitNumber: result.permitNumber,
                                    }}
                                  >
                                    public.business_search.permit_number
                                  </Trans>
                                </Text>
                                <Text variant="p-sm" color="gray">
                                  <Trans
                                    components={{
                                      registrationDate: new Date(
                                        result.registrationDate
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        day: "2-digit",
                                        month: "2-digit",
                                      }),
                                    }}
                                  >
                                    public.business_search.registration_date
                                  </Trans>
                                </Text>
                              </Box>
                            </ResultInfo>
                          </Result>
                        );
                      })}
                    </ResultsGrid>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap="2"
                      flexDirection="column"
                      marginTop={4}
                    >
                      <NotFoundCircle>
                        <Icon name="manage_search" />
                      </NotFoundCircle>
                      <Text variant="h4">
                        <Trans>public.business_search.no_results_to_show</Trans>
                      </Text>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
        </Content>
      </MainMenu>
    </>
  );
};

export default BusinessSearch;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
